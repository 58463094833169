<template>
  <div id="divapp">
    <div>
      <v-carousel hide-delimiters cycle height="100%">
        <v-carousel-item
          v-for="(item,i) in items"
          :key="i"
          :src="item.src"
        ></v-carousel-item>
      </v-carousel>
    </div>

      <v-img
        :src="require('../assets/banner.svg')"
        id="floatimg"
        max-height="450"
      >
        <v-container class="mt-12 pt-9" id="containerpadding">
          <v-row align="center" justify="center">
            <v-col cols="auto">
              <v-img
                src="https://www.premierelabincph.com/assets/frontend/icon/booking_2.svg"
                width="50"
                height="50"
                contain
              >
              </v-img>
            </v-col>
            <v-col cols="12" sm="6" md="6" lg="4" xl="3">
              <p class="title mb-0 white--text">Schedule Appointment</p>
              <p class="mt-0 white--text caption">Book your appointment 2 to 3 days prior to the chosen date of your procedure.</p>
            </v-col>

            <v-spacer></v-spacer>

            <v-col cols="2" align-self="start">
              <v-btn block color="white" class="red--text darken--5">Book Now</v-btn>
            </v-col>

            <v-col cols="12" align-self="center">
              <v-row align="center" justify="center">
                <v-col cols="5" align-self="center">
                  <p class="black--text title font-weight-light text-center" id="divclass1">
                    "We provide a team of dedicated and well trained Sonographers, 
                    Sonologists and Cardiologists to provide highly reliable results 
                    essential to day-to-day care of patients with cardiovascular disease."
                  </p>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-img>
 
    <v-container>
      <v-row align="center" justify="center">
        <v-col cols="10">
          <v-slide-group
            show-arrows
          >
            <v-slide-item
              v-for="btn in btns"
              :key="btn"
            >
              <div class="pl-5 pr-5">
                  <v-row align="center" justify="center">
                    <v-btn
                    id="btnlist"
                    class="ma-7 mb-5"
                    depressed
                    fab
                    x-large
                  >
                  <v-img
                    :src="btn.img"
                    contain
                    height="50"
                  ></v-img>
                </v-btn>
                  </v-row>
                <p class="text-center" id="textlabel">{{ btn.text }}</p>
              </div>
            </v-slide-item>
          </v-slide-group>
        </v-col>

        <v-col cols="10">
          <v-carousel hide-delimiters cycle class="mb-5 mt-7">
            <v-carousel-item
              v-for="(card,i) in cards"
              :key="i"
            >
            <v-card flat color="white">
              <v-card-title class="black--text">{{ card.text }}</v-card-title>
                <v-container>
                  <v-row>
                    <v-col cols="6">
                      <v-img
                        :src="card.img"
                        contain
                        height="439"
                      ></v-img>
                    </v-col>
                    <v-col cols="6">
                      <v-card color="#f0f0f0" flat>
                        <p class="black--text pa-6 subtitle">
                          {{ card.desc }}
                        </p>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-carousel-item>
          </v-carousel>
        </v-col>
      </v-row>
    </v-container>
    <div>
      <v-card tile color="#911416" width="100%" class="pt-5 pb-5 white--text">
        <v-container>
          <v-row align="center" justify="center">
            <v-col cols="5">
              <p class="text-center mt-0 mb-0">
                <a href="https://www.premierelabincph.com/site/services" class="white--text">About Us</a> / 
                <a href="https://www.premierelabincph.com/site/services" class="white--text">Online Results</a> /
                <a href="https://www.premierelabincph.com/site/services" class="white--text">Online Appointment</a> /
                <a href="https://www.premierelabincph.com/site/services" class="white--text">Online HMO</a> /
                <a href="https://www.premierelabincph.com/site/services" class="white--text">Services</a> /
                <a href="https://www.premierelabincph.com/site/services" class="white--text">Preparation</a> /
                <a href="https://www.premierelabincph.com/site/services" class="white--text">Affiliated HMO</a> /
                <a href="https://www.premierelabincph.com/site/services" class="white--text">Doctor's Clinic</a> /
                <a href="https://www.premierelabincph.com/site/services" class="white--text">Careers</a> /
                <a href="https://www.premierelabincph.com/site/services" class="white--text">Contacts</a> /
                <a href="https://www.premierelabincph.com/site/services" class="white--text">News Update</a> /
              </p>
            </v-col>

            <v-col cols="10">
              <p class="text-center mt-0 mb-0" id="t2">
                "Optimize your well-being through compassionate, affordable, reliable, exceptional healthcare service."
              </p>
            </v-col>

            <v-col cols="10">
              <p class="text-center mt-0 mb-0" id="t1">
                <strong>
                  Experience Premiere Care
                </strong>
              </p>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </div>

    <div>
      <v-card tile color="#911416" width="100%" height="20" class="mt-2">
        
      </v-card>
      <v-card class="mt-4 pt-4" flat color="white">
        <v-row align="center" justify="center">
          <v-col cols="12">
            <v-img
              src="https://www.premierelabincph.com/assets/frontend/img/premiere_logo.svg"
              contain
              max-height="90"
            ></v-img>
          </v-col>

          <v-col cols="3">
            <p class="text-center caption">
              Copyright © 2021 - Premiere Medical & Cardiovascular Laboratory
              Design & Developed by Raksquad IT Solutions Inc.
            </p>
          </v-col>
        </v-row>
      </v-card>
    </div>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        model: null,
        cards: [
          {
            text: 'COLON CANCER OVERVIEW',
            img: 'https://www.premierelabincph.com/uploads/news/HC32DOW29L-2020-02-19.jpg',
            desc: 'Colon cancer is a type of cancer that begins in the large intestine (colon). The colon is the final part of the digestive tract. Colon cancer typically affects older adults, though it can happen at any age. It usually begins as small, noncancerous (benign) clumps of cells called polyps that form on the inside of the colon. Over time some of these polyps can become colon cancers. Polyps may be small and produce few, if any, symptoms. For this reason, doctors recommend regular screening tests to help prevent colon cancer by identifying and removing polyps before they turn into cancer. If colon cancer develops, many treatments are available to help control it,',
            link: '',
          },
          {
            text: 'DOH acts on meds distribution, overstocking issues',
            img: 'https://www.premierelabincph.com/uploads/news/J3P4J1D870-2019-09-04.jpg',
            desc: `MANILA – The Department of Health (DOH) on Thursday said it is taking necessary actions in response to the Commission on Audit's (COA) 2018 report on the PHP295.767 million worth of expiring medicines and medical supplies. In a statement, the DOH clarified it has been distributing near expiry drugs and medicines since January 2019. "To date, all stocks of filariasis kits, CD4 cartridge kits, Tuberculin PPD, and Japanese encephalitis vaccines have already been distributed," the DOH said.`,
            link: '',
          },
          {
            text: 'DRIVE-THRU EXPRESS BLOOD EXTRACTION',
            img: 'https://www.premierelabincph.com/uploads/news/6I0A7DYSO4-2020-08-20.jpg',
            desc: 'In support for the health and protection of the public while safely providing convenient service, PREMERE LAB QUEZON CITY offers drive thru blood extraction while remaining inside the comfort of your vehicle. While people are avoiding hospitals for their lab tests, drive-thru extraction saves time and reduces the risks of infection between visitors and laboratory staff. All Drive thru transactions must be pre-scheduled and pre-paid. Secure your slot at least a day before your preferred schedule. You may reach us at 84269745 or 09052959978 / 092128946893 to book an appointment.',
            link: '',
          },
          {
            text: `We're back!`,
            img: 'https://www.premierelabincph.com/uploads/news/9BAAZ2I5ND-2020-06-08.jpg',
            desc: `You can now book your appointments! Just call the following numbers. Don't forget to check our New Normal guidelines. Contact Details: 8426-9745 /0905-295-9978 /0921-284-6893`,
            link: '',
          },
          {
            text: 'NEW LANDLINE NUMBER',
            img: 'https://www.premierelabincph.com/uploads/news/LU0JPR71V3-2020-02-27.jpg',
            desc: 'Due to the PLDT Migration, our old 7 digit number will no longer be reachable effective October 6, 2019. New Landline number: 8426-9745',
            link: '',
          },
          {
            text: 'CORONAVIRUS DISEASE (COVID-19) OUTBREAK',
            img: 'https://www.premierelabincph.com/uploads/news/81VPML32I7-2020-02-27.jpg',
            desc: 'Coronaviruses (CoV) are a large family of viruses that cause illness ranging from the common cold to more severe diseases such as Middle East Respiratory Syndrome (MERS-CoV) and Severe Acute Respiratory Syndrome (SARS-CoV). A novel coronavirus (nCoV) is a new strain that has not been previously identified in humans. Coronaviruses are zoonotic, meaning they are transmitted between animals and people. Detailed investigations found that SARS-CoV was transmitted from civet cats to humans and MERS-CoV from dromedary camels to humans. Several known coronaviruses are circulating in animals that have not yet infected humans. Common',
            link: '',
          },
        ],
        items: [
          {
            src: 'https://www.premierelabincph.com/uploads/slider_gallery/8IY4T19H2Q-2019-12-16.png',
          },
          {
            src: 'https://www.premierelabincph.com/uploads/slider_gallery/99GUKE0UQJ-2020-02-27.jpg',
          },
          {
            src: 'https://www.premierelabincph.com/uploads/slider_gallery/T03FPOFS2K-2020-02-14.png',
          },
          {
            src: 'https://www.premierelabincph.com/uploads/slider_gallery/L76662D24A-2020-02-14.png',
          },
          {
            src: 'https://www.premierelabincph.com/uploads/slider_gallery/TIQ0V1CEFB-2020-06-09.jpg',
          },
          {
            src: 'https://www.premierelabincph.com/uploads/slider_gallery/0SHNAFOYGC-2020-06-22.jpg',
          },
          {
            src: 'https://www.premierelabincph.com/uploads/slider_gallery/LHPUX34YII-2020-08-29.jpg',
          },
          {
            src: 'https://www.premierelabincph.com/uploads/slider_gallery/5C6ILBFYQ7-2020-08-29.jpg',
          },
        ],

        btns: [
          {
            img: 'https://www.premierelabincph.com/assets/frontend/icon/online_booking.svg',
            text: 'ONLINE RESULT'
          },
          {
            img: 'https://www.premierelabincph.com/assets/frontend/icon/online_booking.svg',
            text: 'ONLINE APPOINTMENT'
          },
          {
            img: 'https://www.premierelabincph.com/assets/frontend/icon/online_hmo.svg',
            text: 'ONLINE HMO'
          },
          {
            img: 'https://www.premierelabincph.com/assets/frontend/icon/home_service.svg',
            text: 'HOME SERVICE'
          },
          {
            img: 'https://www.premierelabincph.com/assets/frontend/icon/branches.svg',
            text: 'BRANCHES'
          },
          {
            img: 'https://www.premierelabincph.com/assets/frontend/icon/preperation.svg',
            text: 'PREPARATION'
          },
          {
            img: 'https://www.premierelabincph.com/assets/frontend/icon/services.svg',
            text: 'SERVICES'
          },
          {
            img: 'https://www.premierelabincph.com/assets/frontend/icon/affiliated_hmo.svg',
            text: 'AFFILIATED HMO'
          },
          {
            img: 'https://www.premierelabincph.com/assets/frontend/icon/letter_of_authority.svg',
            text: 'LETTER OF AUTHORITY'
          },
          {
            img: 'https://www.premierelabincph.com/assets/frontend/icon/rewards.svg',
            text: 'REWARDS'
          },
          {
            img: 'https://www.premierelabincph.com/assets/frontend/icon/doctors_clinic.svg',
            text: 'DOCTORS CLINIC'
          },
          {
            img: 'https://www.premierelabincph.com/assets/frontend/icon/career.svg',
            text: 'CAREERS'
          },
          {
            img: 'https://www.premierelabincph.com/assets/frontend/icon/news_updates.svg',
            text: 'NEWS UPDATE'
          },
        ]
      }
    },
  }
</script>