<template>
  <div>
    <div>
      <v-card app flat color="white" height="130" class="pl-5 pr-5">
        <v-container fluid>
            <v-row align="center">
                <v-col cols="6">
                    <v-row align="center" justify="start">
                        <v-card flat width="350">
                            <v-img
                                src="https://www.premierelabincph.com/assets/frontend/img/premiere_logo.svg"
                                contain
                            ></v-img>
                        </v-card>
                    </v-row>
                </v-col>

                <v-col cols="6">
                    <v-row align="center" justify="end">
                        <v-card flat width="350" color="#911416" dark class="pa-2">
                            <div class="mb-1">
                                <span class="font-weight-light body-2">Social media:</span>
                                <v-btn icon small class="ml-1 mr-1">
                                    <v-icon>
                                        fab fa-facebook-f
                                    </v-icon>
                                </v-btn>

                                <v-btn icon small class="ml-1 mr-1">
                                    <v-icon>
                                        fab fa-twitter
                                    </v-icon>
                                </v-btn>

                                <v-btn icon small class="ml-1 mr-1">
                                    <v-icon>
                                        fab fa-instagram
                                    </v-icon>
                                </v-btn>
                            </div>

                            <div>
                                <span class="font-weight-light body-2">Contacts: (02) 8426-9745</span>
                            </div>

                            <div class="mb-1">
                                <span class="font-weight-light body-2">09052959978(Globe) / 09212846893(Smart)</span>
                            </div>

                            <div>
                                <span class="font-weight-light body-2">Email: inquiry@premierelabincph.com</span>
                            </div>
                        </v-card>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
    </v-card>
    </div>
    <div>
        <v-card width="100%" color="#911416" flat tile dark>
            <v-container fluid>
                <v-row>
                    <v-col class="pt-1 pb-1 pr-1" cols="auto">
                        <v-btn small text class="caption font-weight-light">HOME</v-btn>
                    </v-col>
                    <v-col class="pt-1 pb-1 pr-1 pl-1" cols="auto">
                        <v-btn small text class="caption font-weight-light">ABOUT US</v-btn>
                    </v-col>
                    <v-col class="pt-1 pb-1 pr-1 pl-1" cols="auto">
                        <v-btn small text class="caption font-weight-light">CERTIFICATION</v-btn>
                    </v-col>
                    <v-col class="pt-1 pb-1 pr-1 pl-1" cols="auto">
                        <v-btn small text class="caption font-weight-light">CAREERS</v-btn>
                    </v-col>
                    <v-col class="pt-1 pb-1 pr-1 pl-1" cols="auto">
                        <v-btn small text class="caption font-weight-light">CONTACTS</v-btn>
                    </v-col>

                    <v-spacer></v-spacer>

                    <v-col class="pt-1 pb-1 pr-1 pl-1" cols="auto">
                        <v-btn small text class="caption font-weight-light">ADD FEEDBACKS</v-btn>
                    </v-col>
                    <v-col class="pt-1 pb-1 pr-1 pl-1" cols="auto">
                        <v-btn small text class="caption font-weight-light">SIGN IN</v-btn>
                    </v-col>
                    <v-col class="pt-1 pb-1 pl-1" cols="auto">
                        <v-btn small text class="caption font-weight-light">SIGN UP</v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
    </div>
  </div>
</template>

<script>
export default {
    data () {
        return {
            drawer: false,
        }
    }
}
</script>